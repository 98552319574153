<template>
  <div>
    <div class="summary">
      <div class="summary-item">
        <div class="summary-item-title">合伙人人数</div>
        <div class="summary-item-number">{{ summary.member || 0 }}</div>
      </div>
      <div class="summary-item">
        <div class="summary-item-title">今日新增合伙人人数</div>
        <div class="summary-item-number">{{ summary.newMember || 0 }}</div>
      </div>
      <div class="summary-item">
        <div class="summary-item-title">分销邀请客户数</div>
        <div class="summary-item-number">{{ summary.customer || 0 }}</div>
      </div>
    </div>

    <CountLine />

    <Table />

  </div>
</template>

<script>
import * as saleStatistics from '@/api/saleStatistics'
import { getDateRange } from '../common/helper'
import CountLine from './countLine'
import Table from './table'

export default {
  components: {
    CountLine,
    Table
  },
  data () {
    return {
      summary: {
        member: 0,
        newMember: 0,
        customer: 0
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const [member, newMember, customer] = await Promise.all([
        saleStatistics.member({}),
        saleStatistics.newMember(getDateRange(1)),
        saleStatistics.customer({})
      ])
      this.summary = { member, newMember, customer }
    }
  }
}
</script>

<style scoped lang="scss">
.summary {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #FFFFFF;
  height: 140px;
  &-item{
    height: 100%;
    display: grid;
    align-content: center;
    text-align: center;
    border-right: 1px solid #D9E0F0;
    &-title{
      font-size: 16px;
      color: #7D7E8E;
    }
    &-number {
      font-size: 32px;
      font-weight: bold;
      color: #505162;
      line-height: 42px;
    }
  }
}

</style>
